import React from "react";
import {withTranslation} from "react-i18next";

class AboutUs extends React.Component{
    render(){
        const {t} = this.props;
        return (
            <div className="about-us section">
                <div className="container">
                    <h1>{t('about-us')}</h1>
                    <div className="inner">
                        <div className="left">
                            <svg className="svg">
                                <clipPath id="logo-path" clipPathUnits="objectBoundingBox">
                                    <path d="M0.983,0.429 c-0.019,-0.044,-0.046,-0.082,-0.078,-0.117 c-0.077,-0.083,-0.168,-0.144,-0.264,-0.198 c-0.081,-0.045,-0.161,-0.09,-0.252,-0.108 c-0.085,-0.017,-0.162,-0.002,-0.226,0.064 C0.128,0.107,0.102,0.15,0.081,0.196 C0.023,0.328,0.004,0.469,0,0.614 c0.001,0.074,0.007,0.147,0.03,0.217 c0.026,0.08,0.07,0.142,0.152,0.162 c0.048,0.012,0.097,0.008,0.145,-0.004 c0.186,-0.046,0.354,-0.134,0.513,-0.245 c0.05,-0.035,0.094,-0.076,0.128,-0.13 C1,0.555,1,0.494,0.983,0.429"/>
                                </clipPath>
                            </svg>
                            <div className="clip-wrapper">
                                <div className="top-left"/>
                                <div className="bottom-right"/>
                                <div className="clip">
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <h3>We are here to IT Solution with 20 years of experience</h3>
                            <ul>
                                {t('about-us-list', { returnObjects: true }).map(li => <li>{li}</li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(AboutUs);