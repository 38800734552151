import React from "react";
import './assets/css/styles.scss';
import Components from "./components";
import {GoChevronDown} from "react-icons/go";

import welcome from "./assets/img/welcome.jpg";
const config = require("./assets/config.json")

function App() {
    return (
        <div className="app">
            <header className="header">
                <div className="background" style={{backgroundImage: `url(${welcome})` }}/>
                <div className="container">
                    <Components.Navigation sections={config.sections}/>
                    <div className="slides">
                        <div className="slide">
                            <h2>WE ARE CREATIVE BEST</h2>
                            <h1>IT SOLUTION AGENCY COMPANY</h1>
                            <div className="btn">
                                contact
                            </div>
                        </div>
                    </div>
                    <GoChevronDown className="bounce down" size={24}/>
                </div>
            </header>
            <Components.AboutUs/>
            <Components.Service/>
            <Components.Contact/>
            <Components.Footer sections={config.sections} contact={config.contact} footer={config.footer}/>
        </div>
    );
}

export default App;
