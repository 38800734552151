import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";

require('typeface-montserrat');


ReactDOM.render(

    <I18nextProvider i18n={i18n} t={i18n.t}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
    </I18nextProvider>,
  document.getElementById('sc-app')
);