import React from "react";
import {withTranslation} from "react-i18next";
import Icons from "../assets/img/Icons";

class Service extends React.Component {
    render() {
        const {t} = this.props;
        return (
            <div className="service section">
                <div className="container">
                    <h1>{t('service')}</h1>
                    <div className="row">
                        <div className="col">
                            <div className="icon">
                            <Icons type="rocket" size="50%" color="white"/>
                            </div>
                            <h4>Rocket</h4>
                        </div>
                        <div className="col">
                            <div className="icon">
                                <Icons type="hands" size="70%" color="white"/>
                            </div>
                            <h4>Hands</h4>
                        </div>
                        <div className="col">
                            <div className="icon">
                            <Icons type="chart" size="50%" color="white"/>
                            </div>
                            <h4>Chart</h4>
                        </div>
                        <div className="col">
                            <div className="icon">
                            <Icons type="bulb" size="50%" color="white"/>
                            </div>
                            <h4>Bulp</h4>
                        </div>
                    </div>
                    <h2>Focus on Your Business</h2>
                    <div className="inner">
                        <div className="left">
                            <h3>Many of the world’s top brands have chosen Integrio to help them design and deliver
                                innovative products.</h3>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat
                                mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra non, semper
                                suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci.
                                Aenean dignissim pellentesque felis. Morbi in sem quis dui placerat ornare. Pellentesque odio nisi, euismod in, pharetra a,
                                ultricies in, diam. Sed arcu. Cras consequat.</p>
                            <p>Praesent dapibus, neque id cursus faucibus, tortor neque egestas auguae, eu vulputate
                                magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan
                                porttitor, facilisis luctus, metus. Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula
                                vulputate sem tristique cursus. Nam nulla quam, gravida non, commodo a, sodales sit
                                amet, nisi.</p>
                        </div>
                        <div className="right">
                            <div className="square-wrapper">
                                <div className="top-left"/>
                                <div className="bottom-right"/>
                                <div className="square"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Service);