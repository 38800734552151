import React from "react";
import {withTranslation} from "react-i18next";

class Footer extends React.Component {
    render() {
        const {sections, contact, footer, t} = this.props;
        const d = new Date();
        const year = d.getFullYear();
        return (
            <footer className="footer">
                <div className="top">
                    <div className="container">
                        <h3>Navigation</h3>
                        <ul>
                            {sections.map(nav => <li>{t(nav)}</li>)}
                        </ul>
                    </div>
                </div>
                <div className="bottom">
                    <div className="container">
                        <div className="inner">
                            &copy;{year} - S Consulting
                            <ul>
                                {footer.map(nav => <li>{t(nav)}</li>)}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default withTranslation()(Footer);