import React from "react";
import { withTranslation } from "react-i18next";

class Navigation extends React.Component{
    render(){
        const {sections} = this.props;
        const {t} = this.props;
        return (
            <div className="navigation">
                <div className="logo">
                    {t('logo')}
                </div>
                <div className="nav">
                    {
                        sections.map(nav => <span key={nav}>{nav}</span>)
                    }
                </div>
            </div>
        )
    }
}

export default withTranslation()(Navigation);