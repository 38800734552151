import React from "react";

export default class Icons extends React.Component {
    Icon = (type, color = false, size = false, width = false, height = false) => {
        if (!color) {
            color = "#2F2F2F";
        }
        if (!size) {
            size = 20;
        }
        const props = this.props;
        if(type && type !== ""){
            switch (type.toLowerCase()) {
                case "rocket":
                    return (
                        <svg viewBox="0 0 116 116.25" {...props} width={width || size} height={height || size}>
                            <g fill={color}>
                                <path d="M53.81 109.44l-16-16L39.23 92l14.57 14.61c9.07-9.32 15.24-20.73 17-31.44l2 .32C70.91 86.88 64.25 99 54.51 108.74z" />
                                <path d="M62.71 88l-1.51-1.27c3.26-3.79 6.56-7.83 9.8-12a319.19 319.19 0 0041.54-71.27A319.41 319.41 0 0041.29 45l-1.23-1.58C77.74 14.3 113.61.88 114 .75l2-.75-.75 2c-.13.35-13.55 36.22-42.67 73.9-3.26 4.25-6.58 8.32-9.87 12.1z" />
                                <path d="M23 78.68L6.56 62.2l.71-.71c9.73-9.74 21.86-16.4 33.24-18.26l3.91-.64L41.29 45a281.54 281.54 0 00-12 9.8L28 53.3c2.83-2.45 5.74-4.86 8.68-7.2-9.44 2.5-19.14 8.21-27.26 16.1l15.04 15.07zM63.53 57a4.52 4.52 0 113.19-1.32A4.49 4.49 0 0163.53 57zm0-7a2.52 2.52 0 00-1.78 4.3 2.58 2.58 0 003.56 0 2.52 2.52 0 00-1.78-4.3zM78 42.57a4.51 4.51 0 113.19-1.32A4.52 4.52 0 0178 42.57zm0-7a2.52 2.52 0 00-1.78 4.3 2.58 2.58 0 003.56 0 2.53 2.53 0 00.73-1.78A2.51 2.51 0 0078 35.54z" />
                                <path d="M36 96.54l-16-16 .63-.71c.72-.8 1.48-1.6 2.27-2.39l.22-.21c10-9.82 25.06-10.53 25.7-10.55l1.08-.05v1.08c0 .64-.73 15.71-10.58 25.74l-.19.19c-.78.78-1.58 1.55-2.38 2.26zM22.72 80.47L36 93.78c.54-.51 1.08-1 1.61-1.55l.14-.15c7.84-8 9.59-19.67 9.95-23.31-3.65.36-15.34 2.11-23.28 9.92l-.18.17c-.5.53-1.02 1.07-1.52 1.61zM8.42 84.52l1.25-6.09-5.46.4 1.2-4.9L0 72.75l1.73-5.13 1.89.64-1.01 3.01 5.23 1.14-1.03 4.22 5.34-.39L11 81.89l2.94-.66.44 1.95-5.96 1.34zM43.86 116.25l-1.56-4.78-4.07.54-.21-4.66-5.11.46.55-4.95 1.98.22-.27 2.52 4.75-.42.21 4.56 3.56-.47 1.5 4.63 2.48-.57.45 1.95-4.26.97zM3.17 112.51l9.49-13.76-6.25 1.14 9.33-15.16 1.71 1.05-6.98 11.34 6.57-1.2-7.99 11.58 11.09-5.26v5.2l10.62-8.34 1.23 1.57-13.85 10.89v-6.16l-14.97 7.11z" />
                            </g>
                        </svg>
                    );
                case "hands":
                    return (
                        <svg viewBox="0 0 159.94 86.87" {...props} width={width || size} height={height || size}>
                            <g fill={color}>
                                <path d="M27.2 62.44L0 47.16l.98-1.74 25.47 14.31L47.5 22.98 15.45 1.67 16.56 0l33.61 22.35L27.2 62.44zM132.73 62.44l-22.96-40.09L143.38 0l1.11 1.67-32.06 21.31 21.05 36.75 25.48-14.31.98 1.74-27.21 15.28zM107.67 74.26a6.5 6.5 0 01-3.67-1.13l-.24-.19-.69-.55.65-.76.72-.7.67.54a4.57 4.57 0 006.23-6.53l-2-1.6.77-.75.58-.73 2.17 1.77a6.57 6.57 0 01-5.18 10.63z" />
                                <path d="M97.63 80.36a6.53 6.53 0 01-3.69-1.13l-.46-.34 1.18-1.61.43.32a4.58 4.58 0 007.11-3.82 4.52 4.52 0 00-1.1-3l-1.38-1.13.77-.75.59-.72 1 .84h.09l.31.34a6.58 6.58 0 01-4.88 11z" />
                                <path d="M92.21 84.69l-1.41-1.42A4.6 4.6 0 0092.17 80a4.56 4.56 0 00-1-2.79l-1.7-1.39 1.27-1.54 1.92 1.6A6.47 6.47 0 0194.17 80a6.57 6.57 0 01-1.96 4.69zM118 69a6.17 6.17 0 01-1.8-.26l-.18-.06-3.55-2.82 1.25-1.56 3.24 2.57a3.8 3.8 0 001 .13 4.59 4.59 0 003.41-7.65L120 58.26l.58-.75.33-1 .19.07 1.67 1.31A6.59 6.59 0 01118 69zM99.391 55.422l1.247-1.564 13.043 10.397-1.247 1.564zM92.108 63.482l1.262-1.552 7.69 6.252-1.262 1.551zM103.97 73.12l-.91-.73-1.93-1.57 1.27-1.55 2.76 2.25-.52.54-.67 1.06z" />
                                <path d="M93.91 79.21l-2.58-1.89-9.7-7.91 1.27-1.56 9.66 7.89 2.53 1.86-1.18 1.61zM120 58.25L88.33 33.76H70.75c-.91.59-3.76 2.15-9 2.18h-.1c-3.12 0-5.52-.87-7.13-2.58a7.93 7.93 0 01-2-5.54c.28-2.61 5-2.53 8.12-2.47h.79c.92 0 4.93-2.61 8.1-5l.27-.21 19.44.05c10.39 3.32 22.09 6.38 23.67 5.75l1 1.72c-2.39 1.49-18.26-3.32-25.15-5.52h-18.3c-1.93 1.47-7.09 5.25-9 5.25h-.83c-4.59-.08-5.86.33-6.1.68A6.14 6.14 0 0056 32c1.23 1.29 3.18 1.93 5.75 1.93 5.54 0 8-1.94 8-2l.28-.22H89l32.18 24.9zM23.33 49.33a3.06 3.06 0 113.06-3 3.06 3.06 0 01-3.06 3zm0-4.11a1.06 1.06 0 101.06 1.06 1.05 1.05 0 00-1.06-1.06zM137.44 49.15a2.61 2.61 0 112.61-2.61 2.62 2.62 0 01-2.61 2.61zm0-3.21a.61.61 0 10.61.6.6.6 0 00-.61-.6zM76.64 86.87a6.59 6.59 0 01-6.58-6.59v-.07a9.16 9.16 0 01-3 .48 7.31 7.31 0 01-7.24-6.12 7.22 7.22 0 01-3.33.82 7.14 7.14 0 01-7.14-7.14v-.54l-.45.17-.36.33.15-.26a6.59 6.59 0 01-8.42-6.33 6.48 6.48 0 011.8-4.51l1.73-1.63A6.5 6.5 0 0148 54a6.59 6.59 0 016.57 6.14l.39-.35a7 7 0 015.14-2.18 7.14 7.14 0 017.13 6.94 7.32 7.32 0 019.65 6.87 7.17 7.17 0 01-.12 1.31 6.37 6.37 0 011-.07 6.51 6.51 0 015.53 3 6.45 6.45 0 01-.07 7.19l-.1.13-2 2.11a6.48 6.48 0 01-4.48 1.78zM74 76l-1.39 2.62a3.38 3.38 0 00-.57 1.62 4.59 4.59 0 004.58 4.59 4.45 4.45 0 003-1.18l1.93-2a4.37 4.37 0 00.73-2.44 4.63 4.63 0 00-4.59-4.59 4.44 4.44 0 00-2.1.52zm-11.82-6.1l-.37 2.86a5.06 5.06 0 00-.05.68 5.29 5.29 0 005.33 5.25 5.53 5.53 0 004.08-1.47l3.13-3.41a5 5 0 00.59-2.4 5.31 5.31 0 00-8.28-4.34l-2.2 1.51.67-2.58a4.84 4.84 0 00.16-1.26 5.14 5.14 0 00-8.87-3.52l-4.62 4.16.36.25a5.14 5.14 0 008 6.3zM45.15 57l-1.62 1.53a4.47 4.47 0 00-1.21 3.09 4.59 4.59 0 004.58 4.59 4.53 4.53 0 003.57-1.73l.19.14 1.13-2a4.38 4.38 0 00.81-2.08A4.59 4.59 0 0048 56a4.51 4.51 0 00-2.85 1z" />
                                <path d="M60.049 71.99l5.235-6.39 1.547 1.268-5.235 6.39zM71.154 77.222l3.273-3.62 1.483 1.342-3.273 3.62zM50.447 64.517l1.348-1.85 1.617 1.177-1.349 1.851z" />
                            </g>
                        </svg>
                    );
                case "chart":
                    return (
                        <svg viewBox="0 0 108.78 114.9" {...props} width={width || size} height={height || size}>
                            <g fill={color}>
                                <path d="M18.29 107.72h-2V84.81H2v22.91H0V82.81h18.29v24.91zM40.76 107.72h-2V93.46H24.47v14.26h-2V91.46h18.29v16.26zM63.43 107.72h-2V66.66H47.15v41.06h-2V64.66h18.28v43.06zM86.11 107.72h-2v-32.6H69.82v32.6h-2v-34.6h18.29v34.6zM108.78 107.72h-2V36.36H92.5v71.36h-2V34.36h18.28v73.36zM29.85 65.17L5.88 58.38l.54-1.93 22.62 6.41 24.88-34.69 22.62 8.63L98.78 0l1.72 1.03-23.11 38.24-22.74-8.68-24.8 34.58zM0 112.9h108.78v2H0z" />
                            </g>
                        </svg>
                    );
                case "bulb":
                    return (
                        <svg viewBox="0 0 85.54 115.39" {...props} width={width || size} height={height || size}>
                            <g fill={color}>
                                <path d="M28.66 97.41h34.16v2H28.66zM33.9 107.45h25.53v2H33.9z" />
                                <path d="M33.9 107.45h25.53v2H33.9zM28.66 97.41h34.16v2H28.66zM55.53 88.88h-2v-8.65H31.58v8.65h-2V78.23h25.95v10.65z" />
                                <path d="M23.477 44.9l1.938-.492 9.02 35.543-1.938.492zM51.313 79.947l6.882-27.523 1.94.485-6.882 27.523zM18.33 27.23v-.92a10 10 0 019.76-10.16 9.54 9.54 0 012.5.34 9.64 9.64 0 013.63 1.92l-1.29 1.53A7.62 7.62 0 0030 18.42a7.13 7.13 0 00-2-.27 8 8 0 00-7.76 8.16v.74z" />
                                <path d="M39.72 18.29a4.19 4.19 0 00-.65-1.11 4.66 4.66 0 00-3.71-1.86 4.77 4.77 0 00-4.19 2.6L29.4 17a6.76 6.76 0 016-3.67A6.66 6.66 0 0140.65 16a6.55 6.55 0 01.93 1.59z" />
                                <path d="M50.55 19.22a5.53 5.53 0 00-5.55-3.9 5.9 5.9 0 00-4.43 1.9l-1.5-1.33A7.94 7.94 0 0145 13.32a7.54 7.54 0 017.45 5.39z" />
                                <path d="M61.32 25.8l-2-.36a4.11 4.11 0 00.08-.77c0-2.78-2.55-5.05-5.67-5.05a6.14 6.14 0 00-1.93.3 5.57 5.57 0 00-3 2.2L47.2 21a7.57 7.57 0 014-3 8 8 0 012.55-.4c4.23 0 7.67 3.16 7.67 7.05a6.27 6.27 0 01-.1 1.15zM47.63 43H16.39l-.29-.3a10.37 10.37 0 012.8-16.45 9.18 9.18 0 014.87-.88l-.2 2a7.16 7.16 0 00-3.81.7A8.37 8.37 0 0017.25 41h30.38zM57.14 115.39H27.92l-1.83-5.94h-3L19 97.41h3.84v-7.14h40v7.14h3l-3.24 12h-3.45zm-27.74-2h26.31l2-5.94h3.34l2.16-8h-2.4v-7.18h-36v7.14h-3l2.75 8h3.05z" />
                                <path d="M60.36 92.27H25.19l-.26-.19C10.72 82 0 60.84 0 42.77a42.77 42.77 0 1185.54 0c0 18.07-10.71 39.28-24.92 49.31zm-34.54-2h33.91c13.37-9.62 23.81-30.41 23.81-47.5a40.77 40.77 0 10-81.54 0c0 17.08 10.44 37.87 23.82 47.5z" />
                                <path d="M67.51 52.27h-5.66V49a9.12 9.12 0 01-1.38-.57l-2.31 2.31-4-4 2.32-2.31a9.35 9.35 0 01-.58-1.38h-3.26V37.4h3.26a9.31 9.31 0 01.57-1.4l-2.31-2.31 4-4L60.47 32a9.12 9.12 0 011.38-.57v-3.25h5.66v3.27a9.12 9.12 0 011.38.57l2.31-2.31 4 4L72.88 36a8.35 8.35 0 01.57 1.38h3.27v5.65h-3.27a8.72 8.72 0 01-.57 1.38l2.32 2.31-4 4-2.31-2.31a9.12 9.12 0 01-1.38.57zm-3.66-2h1.66v-2.83l.78-.18a7.26 7.26 0 002.23-.92l.68-.43 2 2 1.17-1.17-2-2 .42-.68a7 7 0 00.93-2.23l.17-.78h2.83V39.4h-2.83l-.17-.78a7 7 0 00-.93-2.23l-.42-.68 2-2-1.17-1.17-2 2-.68-.43a7 7 0 00-2.23-.92l-.78-.19v-2.82h-1.66V33l-.78.18a7.26 7.26 0 00-2.23.92l-.67.43-2-2L57 33.71l2 2-.42.67a7 7 0 00-.93 2.24l-.17.78h-2.84v1.65h2.83l.17.78a7 7 0 00.93 2.23l.43.68-2 2 1.17 1.17 2-2 .67.43a7.26 7.26 0 002.23.92l.78.18zM29.445 24.676l1.135-1.647 1.622 1.118-1.135 1.647zM39.98 23.387l2.165-1.99 1.354 1.473-2.165 1.99zM50.962 26.712l2.118-1.455 1.133 1.648-2.118 1.456zM31.525 36.506l1.206-1.595 1.404 1.062-1.207 1.595zM33.951 30.023l1.677-2.292 1.614 1.181-1.677 2.292zM25.68 36.31l-2-.16c0-.49 0-.82-.24-1a3 3 0 00-2.15-.06l-.58-1.92c.26-.08 2.56-.72 4 .42a3 3 0 01.97 2.72z" />
                                <path d="M22.974 33.425l1.934-1.856 1.385 1.442-1.934 1.856zM42.73 36.41L41.31 35a6.12 6.12 0 016.74-1.21l-.84 1.81a4.13 4.13 0 00-4.48.81z" />
                                <path d="M42.51 29.438l1.934-.51 1.351 5.125-1.933.51z" />
                            </g>
                        </svg>
                    );
                default:
                    return "No icon found with that type";
            }
        }else{
            return "Missing icon type specification";
        }
    };

    render() {
        return this.Icon(this.props.type, this.props.color, this.props.size, this.props.width, this.props.height);
    }
}
