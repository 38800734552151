import React from "react";
import {withTranslation} from "react-i18next";

const fields = {
    name: "",
    email: "",
    phone: "",
    message: "",
    captcha: ""
}

class Contact extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            form: fields
        }
    }
    updateForm(field, e){
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value
            }
        })
    }
    render(){
        const {t} = this.props;
        const {form} = this.state;
        return (
            <div className="contact section">
                <div className="container">
                    <h1>{t('contact')}</h1>
                    <div className="contact-form">
                        {
                            Object.keys(form).map(field =>
                                <label className={field}>
                                    <span>{t(field)}</span>
                                    { field !== "message" ?
                                        <input type="text" value={form[field]} onChange={(e) => this.updateForm(field, e)}/>
                                        :
                                        <textarea onChange={(e) => this.updateForm(field, e)}>
                                            {form[field]}
                                        </textarea>
                                    }
                                </label>
                            )
                        }
                        <div className="btn bg-white">{t('send')}</div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(Contact);