import Navigation from "./Navigation";
import AboutUs from "./AboutUs";
import Service from "./Service";
import Contact from "./Contact";
import Footer from "./Footer";

const Components = {
    Navigation: Navigation,
    AboutUs: AboutUs,
    Service: Service,
    Contact: Contact,
    Footer: Footer
}
export default Components;